import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';

// Leaflet CSS tuleb importida, et kaart korrektselt kuvataks
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

// Parandus markeri ikoonide jaoks
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon-2x.png',
  iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
});

const MapComponent = () => {
  const position = [58.3780, 26.7290]; // Tartu koordinaadid

  return (
    <MapContainer center={position} zoom={13} style={{ height: '400px', width: '100%' }}>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      <Marker position={position}>
        <Popup>
          Tere tulemast Tartusse! <br /> Eesti ülikoolilinn.
        </Popup>
      </Marker>
    </MapContainer>
  );
};

export default MapComponent;